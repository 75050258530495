<script lang="ts" setup>
const { visible } = useModalBackground();
</script>

<template>
  <ClientOnly>
    <Teleport to="body">
      <Transition
        enter-from-class="opacity-0"
        enter-active-class="transition-opacity duration-500"
        enter-to-class="opacity-100"
        leave-from-class="opacity-100"
        leave-active-class="transition-opacity duration-200"
        leave-to-class="opacity-0"
      >
        <div
          v-if="visible"
          class="fixed top-0 right-0 bottom-0 left-0 bg-black/50 max-xl:z-2000"
        ></div>
      </Transition>
    </Teleport>
  </ClientOnly>
</template>
